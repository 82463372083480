<template>
<div class="checkpoint-search">
  <text-input 
    v-model="search"
    placeholder="Add a new checkpoint..."
    classes="is-rounded has-addons">
    <template #right>
      <action-button 
        :working="loading"
        @click="searchForCheckpoints"
        class="is-rounded">
        Search Checkpoints
      </action-button>
    </template>
  </text-input>  
  <div class="checkpoint-search-results has-shadow" v-if="got_results">
    <div class="results-header">
      <div v-if="!results.length">
         <p class="has-text-weight-bold" >There are checkpoints available to add to this checklist.</p>
         <router-link></router-link>
      </div>
     
      <div v-else>
        <p class="has-text-weight-bold" >Available Checkpoints ({{ results.length }})</p>
      </div>
      
      <icon @click="closeResults" icon="times-circle" class="has-text-danger" type="far"/>
    </div>
    <div v-for="checkpoint in results" :key="checkpoint.id">
      <div class="selectable-checklist">
        {{ checkpoint.instruction }}
        <action-button class="is-small is-rounded" @click="addToChecklist(checkpoint)">Add to Checklist</action-button>
      </div>
    </div>
  </div>
</div>  
</template>
<script>
import { checklist as backend } from '@/api'
export default {

  props: {
    checklistId: String
  },
  
  data: () => ({
    search: '',
    loading: false,
    results: [],
    got_results: false
  }),

  methods: {
    searchForCheckpoints() {
      this.loading = true
      backend.searchForAvailableCheckpoints({
        checklist_id: this.checklistId,
        search: this.search,
      }, ({data}) => {
        this.results = data
        this.loading = false
        this.got_results = true
      }, () => this.loading = false)
    },
    addToChecklist(checkpoint) {
      this.$store.dispatch('checklist/addCheckpoint', checkpoint).then(({data}) => {
        this.$toast.success('Checkpoint added')
        this.$emit('checkpoint-added', data)
      })
      const index = this.results.indexOf(checkpoint)
      this.results.splice(index, 1)
    },
    closeResults() {
      this.results = []
      this.got_results = false
    }
  }

}
</script>