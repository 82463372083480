<template>
  <loader v-bind="{ loading }" text="Loading Customers">
    <columns>
      <column>
        <add-checkpoints 
          :checklist-id="checklist.uuid"
          @checkpoint-added="addCheckpoint"/>
      </column>
    </columns>
    <columns>
      <column>
        <div class="content">
          <p>Drag and drop checkpoints to rearrange their order.</p>
        </div>
        <draggable handle=".handle" v-model="checkpoints" group="people" @start="drag=true" @end="drag=false" @change="updateSort">
          <checkpoint-row @remove-checkpoint="removeCheckpoint" v-for="(checkpoint, index) in checkpoints" :index="index" :checkpoint="checkpoint" :key="checkpoint.id"/>
        </draggable>
        <p class="has-text-centered" v-if="!checkpoints.length">
          This checklist does not have any checkpoints assigned as yet.
        </p>
      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { checklist as backend } from '@/api'
import CheckpointRow from './partials/CheckpointRow.vue'
import Draggable from 'vuedraggable'
import AddCheckpoints from './partials/AddCheckpoints.vue'
import { mapGetters } from 'vuex'

export default {

  components: {
    CheckpointRow,
    Draggable,
    AddCheckpoints
  },

  data: () => ({
    loading: true,
    checkpoints: []
  }),

  created() {
    this.loadCheckpoints()
  },

  methods: {
    loadCheckpoints() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.checkpoints = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    addCheckpoint(checkpoint) {
      this.checkpoints.push(checkpoint)
    },
    removeCheckpoint(checkpoint) {
      const index = this.checkpoints.indexOf(checkpoint)
      this.checkpoints.splice(index, 1)
    },
    updateSort() {
      backend.updateCheckpointSort({
        checklist_id: this.checklist.uuid,
        checkpoints: this.checkpoints.map(checkpoint => checkpoint.id)
      }, () => {})
    }
  },

  computed: {
    ...mapGetters('checklist', [
      'checklist'
    ]),
  },

  watch: {
    '$route': 'loadCheckpoints'
  }

}
</script>