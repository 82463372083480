<template>
<div class="checkpoint-row">
  <icon icon="grip" class="flex-shrink handle mr-2"/>
  <div class="flex-grow">
    <router-link :to="{
      name: 'edit-checkpoint',
      params: {
        checkpoint: checkpoint.uuid
      }
    }">
      {{ index + 1 }}. {{ checkpoint.instruction }}
    </router-link>
    <br>
    <small class="has-text-grey">{{ checkpoint.type.name }}</small>
  </div>
  <div class="pl-2 has-text-centered">
    <icon icon="camera"/>
    {{ checkpoint.image_configuration.replaceAll('_', ' ') | ucwords }}
  </div>
  <div class="plr-2 has-text-centered">
    <icon icon="note-sticky" type="far"/>
    {{ checkpoint.comment_configuration.replaceAll('_', ' ') | ucwords }}
  </div>
  <div>
    <action-button
      :working="removing"
      @click="remove"
      class="is-small is-rounded is-danger">Remove</action-button>
  </div>
</div>  
</template>
<script>
export default {
  
  props: {
    index: Number,
    checkpoint: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    removing: false
  }),

  methods: {
    async remove() {
      if(await this.$confirm({
        title: 'Remove Checkpoint from checklist',
        message: 'Are you sure you want to remove this checkpoint?'
      })) {
        this.removing = true
        this.$store.dispatch('checklist/removeCheckpoint', this.checkpoint)
          .then(() => {
            this.removing = false
            this.$emit('remove-checkpoint', this.checkpoint)
          })
          .catch(() => this.removing = false)
      }
    }
  }

}
</script>